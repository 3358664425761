import React from "react";

const BtnVerCurso = () => {
    return (
        <button
            className="bg-[#986DFA] font-[Outfit] font-bold text-white text-center
            w-full py-[10px] text-[16px] hover:bg-[#986DFA]/90
            sm:w-[322px] sm:py-[14px] sm:text-[24px]">
            VER CONTENIDO
        </button>
    )
}
export default BtnVerCurso