import React from "react";
import CardCategories from "./CardCategories";

const CardProximosCursos = (props) => {

    return (
        <div
            className="grid w-full xl:w-[575px]
            grid-cols-[96px_1fr] sm:grid-cols-[136px_1fr]">
            <img
                src={props.img}
                alt=''
                className="object-cover h-full w-[96px] sm:w-[136px]" />
            <div
                className="border-[2px] border-[#141414]
                py-[12px] px-[16px] sm:p-[24px]">

                <div className="flex justify-between items-center">
                    <CardCategories category={props.category} color="#986DFA" />
                    <p className="font-[Outfit] font-semibold text-[16px] sm:text-[18px]"> Virtual </p>
                </div>

                <h3 className="font-[Outfit] font-bold text-[18px] sm:font-semibold sm:text-[20px] pt-[16px] pb-[12px]">
                    {props.titulo}
                </h3>
                <p className="pCards text-[#141414]">
                    {props.resumen}
                </p>

            </div>
        </div>
    )
}

export default CardProximosCursos