import React from "react";
import { motion } from "framer-motion";
import CardCategories from "../cards/CardCategories";
import CardProximosCursos from "../cards/CardProximosCursos";
import CardSesiones from "../cards/CardSesiones";
import FormCurso from "../forms/FormCurso";

const Curso1 = () => {

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}>
            <section id="Section 1">
                <div>
                    <img src="/png/headerImg.png" alt='' className="object-cover w-full h-[204px] sm:h-[280px]" />
                </div>
            </section>

            <section id="Section 2"
                className="py-[40px] sm:py-[80px]">
                <div className="container">
                    <div>
                        <h2 className="relative text-[#12113F] text-left z-10"> Principios de UX  </h2>
                        <div className="relative z-0 bg-[#72FFE6]
                            w-[248px] h-[12px] top-[-16px]
                            sm:w-[428px] sm:h-[25px] sm:top-[-36px]">
                        </div>
                    </div>
                    <div
                        className="grid gap-[24px] sm:grid-cols-[110px_1fr] sm:gap-[52px] text-[#12113F]">
                        <div className="grid gap-[16px] sm:gap-[24px]">
                            <div
                                className="flex justify-between items-center font-[Outfit] 
                                sm:grid sm:gap-[24px] sm:justify-start sm:items-start">
                                <p className="font-medium text-[16px] sm:text-[24px]"> 6 sesiones </p>
                                <p className="font-medium text-[12px] sm:text-[16px]"> Virtual </p>
                            </div>
                            <div className="w-[86px] sm:w-[110px]"><CardCategories category="Diseño UX" color="#12113F" /></div>
                        </div>
                        <div
                            className="grid font-[Outfit] font-light items-center
                            text-[14px] gap-[16px] sm:text-[20px] sm:gap-[24px]">
                            <p> Hoy en día los diseñadores nos vemos en la necesidad de adquirir nuevos conocimientos que nos permitan crear experiencias relevantes para un público cada vez más digital. </p>
                            <p> En este curso, aprenderás los fundamentos de UX y conocerás los conceptos más importantes. Además contarás con asesoría personalizada de tu tutor para el desarrollo de un proyecto digital.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Section 3"
                className="py-[40px] sm:py-[80px] bg-azulP">
                <div className="container">
                    <div
                        className="grid gap-[40px] lg:gap-[78px] lg:grid-cols-2">
                        <div className="lg:order-2">
                            <img src="/png/headerImg.png" alt='' className="object-cover mx-auto w-full h-[338px] lg:h-[556px]" />
                        </div>
                        <div className="lg:order-1">
                            <p className="text-white text-left font-[Outfit] font-bold text-[20px] sm:text-[44px] xl:text-[48px]">
                                ¿Qué aprenderás?
                            </p>
                            <p className="font-[Outfit] font-normal text-white text-[16px] py-[16px] sm:text-[24px] sm:py-[36px]">
                                En esta seccion pódras ver los temas a tratar durante el curso.
                            </p>
                            <div className="grid gap-[16px]">
                                <CardSesiones sesion="01" text="Introducción al UX" />
                                <CardSesiones sesion="02" text="Proceso de diseño UX" />
                                <CardSesiones sesion="03" text="Usabilidad" />
                                <CardSesiones sesion="04" text="Proyecto Final" />
                            </div>
                        </div>
                    </div>


                </div>
            </section>

            <section id="Section 4"
                className="py-[40px] sm:py-[80px]">
                <div className="container">
                    <div className="flex justify-center">
                        <div>
                            <h2 className="relative text-[#12113F] z-10"> Conoce al tutor </h2>
                            <div className="mx-auto relative z-0 bg-[#72FFE6]
                                w-[222px] h-[12px] top-[-16px]
                                sm:w-[420px] sm:h-[25px] sm:top-[-36px]">
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex justify-between  w-full gap-[24px] mx-auto
                        lg:grid lg:grid-cols-[1fr_590px] lg:max-w-[867px] sm:gap-[32px]">
                        <img src="/png/tutor-Heison.png" alt="Heison Espinoza"
                            className="w-[96px] h-[96px] sm:w-auto sm:h-auto">
                        </img>
                        <div className="text-azulP">
                            <p className="font-[Playfair] font-medium text-[18px] sm:text-[32px]">
                                Heison Espinoza
                            </p>
                            <p
                                className="font-[Playfair] font-normal
                                text-[12px] py-[8px] sm:text-[18px] sm:py-[24px]">
                                Product Designer {" "}
                                <span className="font-[Outfit] font-semibold">
                                    SODIMAC
                                </span>
                            </p>
                            <p className="pCards"> Amplia experiencia en diseño de productos digitales, consultorías para emprendedores y empresas. </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Section 5"
                className="py-[40px] sm:py-[80px]">
                <div className="container">
                    <div>
                        <p
                            className="relative text-[#12113F] z-10 text-left
                            font-[Outfit] font-bold text-[20px] sm:text-[34px] md:text-[40px] lg:text-[48px]">
                            Cursos que te pueden interesar
                        </p>
                        <div className="relative z-0 bg-[#72FFE6]
                                w-[282px] h-[10px] top-[-12px]
                                sm:w-[480px] sm:h-[20px] sm:top-[-20px]
                                md:w-[564px] md:h-[24px] md:top-[-24px]
                                lg:w-[684px] lg:h-[25px] lg:top-[-30px]">
                        </div>
                    </div>
                    <p className="font-[Outfit] font-normal text-black text-[16px] sm:text-[24px]"> Conoce otros cursos que pueden sumarte más competencias </p>
                    <div
                        className="grid gap-y-[24px] mt-[24px] sm:mt-[40px]
                        lg:grid-cols-2 lg:gap-x-[30px] lg:mt-[56px]">
                        <CardProximosCursos />
                        <CardProximosCursos />
                    </div>
                </div>
            </section>

            <section id="Hero"
                className="w-full grid lg:grid-cols-2 z-0">
                <div
                    className="w-full bg-gradient-to-tr from-[#72FFE6] to-[#27004F] lg:order-2 py-[40px] sm:py-[80px]">
                    <div
                        className="w-[312px] mx-auto
                        sm:w-auto sm:px-[12%] lg:pl-[4%] lg:pr-[8%]
                        xl:max-w-[720px] xl:ml-0 xl:px-[8%]">
                        <div className="flex justify-center">
                            <div>
                                <p
                                    className="relative text-white z-10 text-center
                                    font-[Outfit] font-bold text-[30px] sm:text-[36px] xl:text-[48px]">
                                    ¿Listo para empezar?
                                </p>
                                <div className="mx-auto relative z-0 bg-[#54328C]
                                    w-[309px] h-[12px] top-[-16px]
                                    sm:w-[380px] sm:h-[20px] sm:top-[-24px]
                                    xl:w-[507px] xl:h-[25px] xl:top-[-32px]">
                                </div>
                            </div>
                        </div>
                        <FormCurso />
                    </div>
                </div>
                <div
                    className="bg-azulP lg:order-1 py-[40px] sm:py-[80px] flex items-center" >
                    <div
                        className="grid gap-[24px] text-center items-center text-white 
                        w-[312px] sm:w-auto xl:max-w-[720px]
                        mx-auto sm:px-[12%] xl:mr-0">
                        <img src="/svg/logo.svg" alt="PROTOTIPS" className="w-[116px] h-[24px] mb-[8px] sm:w-[162px] sm:h-[32px] sm:mb-[36px] mx-auto" />
                        <p className="font-[Outfit] font-normal text-[18px] sm:text-[24px]"> Escribenos para mayor información </p>
                        <div className="flex items-center gap-[15px] justify-center">
                            <img src="/svg/icon-correo.svg" alt="" />
                            <p className="font-[Jost] font-medium text-[16px] sm:text-[24px]"> prototips@gmail.com </p>
                        </div>
                        <div className="flex items-center gap-[15px] justify-center">
                            <img src="/svg/icon-llamada.svg" alt="" />
                            <p className="font-[Jost] font-medium text-[16px] sm:text-[24px]"> +51 991231559 </p>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}
export default Curso1