import React from "react";

const BtnEscribenos = () => {
    
    return (
        <button
            className="bg-verdeAgua font-[Outfit] font-bold text-azulP text-center
            w-[117px] text-[16px] py-[8px] hover:bg-verdeAgua/80 transition-all ease-out
            sm:w-[177px] sm:text-[18px]">
            ESCRÍBENOS
        </button>
    )
}
export default BtnEscribenos