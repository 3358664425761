import React from "react";
import { useLocation } from 'react-router-dom'

const Footer = () => {

    const location = useLocation().pathname
    const InHome = (location === "/")
    
    return (
        <div className="flex w-full bg-azulP py-[40px] justify-center text-white border-t-[1px] border-gray-500/100">

            <div
                className="grid gap-[24px] text-center container">
                    { InHome &&
                        <div className="grid gap-[24px] text-center container">
                            <p className="font-[Outfit] font-normal text-[18px] sm:text-[24px]"> Escribenos para mayor información </p>
                            <div className="flex items-center gap-[15px] justify-center">
                                <img src="/svg/icon-correo.svg" alt=""/>
                                <p className="font-[Jost] font-medium text-[16px] sm:text-[24px]"> prototips@gmail.com </p>
                            </div>    
                            <div className="flex items-center gap-[15px] justify-center">
                                <img src="/svg/icon-llamada.svg" alt=""/>
                                <p className="font-[Jost] font-medium text-[16px] sm:text-[24px]"> +51 991231559 </p>
                            </div>
                        </div>
                    }
                    <p
                        className="font-[Jost] font-normal text-[12px] sm:text-[16px]"> 
                        © Copyright 2022 VÉRTICES | All Rights Reserved | Powered by 
                        
                        <span
                            className="font-[Jost] font-bold text-[12px] sm:text-[16px] text-verdeAgua ml-[5px]">
                            CICLOS STUDIO 
                        </span>
                    </p>
            </div>
        </div>
    )
}
export default Footer
