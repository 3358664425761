import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import ScrollToTop from "./components/hooks/ScrollToTop";
import NavBar from "./components/NavBar";
import Curso1 from "./components/pages/Curso1";
import Home from "./components/pages/Home";

function App() {
  const [loaded, setLoaded] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(false)
    }, 3500)
  }, [])
  if (loaded) {
    return (
      <AnimatePresence>
        <div
          className=" bg-[#12113F] h-[100vh] w-[100vw] flex 
          justify-center items-center">
          <motion.img
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 2 }}
            transition={{ type: "spring", stiffness: 20, duration:3 }}
            src="/svg/logo.svg"
            alt="PROTOTIPS"
            className="w-[100px] md:w-[190px]">
          </motion.img>
        </div>
      </AnimatePresence>
    )
  } else {
    return (
      <AnimatePresence>
        <BrowserRouter>
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Curso1" element={<Curso1 />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AnimatePresence>
    )
  }
}

export default App;
