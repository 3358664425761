import React from "react";

const CardCategories = (props) => {
    
    return (
        <div
            style={{color: props.color, borderColor: props.color}}
            className="w-auto border-[1px] rounded-[8px] pCards font-medium
            py-[4px] px-[8px] sm:px-[16px]">
            {props.category}
        </div>
    )
}
export default CardCategories
