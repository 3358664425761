import React from "react";
import { useMediaQuery } from 'react-responsive'
import { useState } from "react";
import { Link } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import BtnEscribenos from "./buttons/BtnEscribenos";

const NavBar = () => {

   const location = useLocation().pathname
   const isLg = useMediaQuery({ query: '(min-width: 1024px)' })

   const InHome = (location === "/")
   const bgNavBar = { background: InHome ? '' : '#12113F' }

   const [menu, setMenu] = useState(false)

   return (
      <div className="absolute top-0 w-full z-20">
         <div
            style={bgNavBar}
            className="flex justify-center items-center h-[60px] sm:h-[80px]" >
            <nav className="flex justify-between items-center
                    w-[312px] sm:w-full sm:mx-[8%] xl:mx-0 xl:w-[1180px] mx-auto ">
               <LinkR to='/'>
                  <img src="/svg/logo.svg" alt="PROTOTIPS" className="w-[130px] sm:w-[180px] md:w-[211px]" />
               </LinkR>

               {isLg ?
                  <div
                     className="flex items-center gap-[64px]
                            font-[Outfit] font-medium text-white text-[18px]">
                     {InHome &&
                        <>
                           <Link
                              to="nuestrosCursos"
                              smooth={true}
                              duration={500} >
                              <p className="cursor-pointer"> Cursos actuales </p>
                           </Link>
                           <Link
                              to="proximosCursos"
                              smooth={true}
                              duration={500} >
                              <p className="cursor-pointer"> Próximos cursos </p>
                           </Link>
                        </>
                     }
                     <a
                        rel="noreferrer"
                        target="_blank"
                        href={"https://api.whatsapp.com/send?phone=+51933509472&text=Quiero%20saber%20m%C3%A1s%20sobre%20Prototips"}
                     >
                        <BtnEscribenos />
                     </a>
                  </div>
                  :
                  <div
                     onClick={() => setMenu(true)}
                     className="cursor-pointer" >
                     <img src="/svg/burger.svg" alt="" />
                  </div>
               }

            </nav>
            {
               menu && !isLg &&
               <div
                  className='bg-white h-full w-[232px] top-0 fixed right-0 z-30
                        font-[Outfit] font-semibold text-azulP text-[20px]'>
                  <div
                     onClick={() => setMenu(false)}
                     className="py-[9px] pl-[15px] cursor-pointer">
                     <img src="/svg/close.svg" alt="X" />
                  </div>
                  <div
                     onClick={() => setMenu(false)}
                     className="p-[24px] hover:bg-gray-200 cursor-pointer">
                     <Link
                        to="nuestrosCursos"
                        smooth={true}
                        duration={500} >
                        <p className="cursor-pointer"> Cursos actuales </p>
                     </Link>
                  </div>
                  <div
                     onClick={() => setMenu(false)}
                     className="p-[24px]  hover:bg-gray-200 cursor-pointer border-y-[1px] mb-[24px] border-[#181818]">
                     <Link
                        to="proximosCursos"
                        smooth={true}
                        duration={500} >
                        <p className="cursor-pointer"> Próximos cursos </p>
                     </Link>
                  </div>
                  <a
                     rel="noreferrer"
                     target="_blank"
                     href={"https://api.whatsapp.com/send?phone=+51933509472&text=Quiero%20saber%20m%C3%A1s%20sobre%20Prototips"}
                     className="flex justify-center">
                     <BtnEscribenos />
                  </a>
               </div>
            }
         </div>
      </div>
   )
}
export default NavBar