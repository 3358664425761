import React from "react";
import { motion } from "framer-motion";
import BtnHero from "../buttons/BtnHero";
import CardCurso from "../cards/CardCurso";
import CardElegirnos from "../cards/CardElegirnos";
import CardProximosCursos from "../cards/CardProximosCursos";
import { useMediaQuery } from 'react-responsive'
import HeroTitle from "../cards/HeroTitle";
import { Link } from "react-scroll";

const Home = () => {

    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}>
            <section id="Hero"
                className="w-full grid lg:grid-cols-2 z-0">
                <div
                    className="w-full bg-azulP
                    pt-[76px] sm:pt-[120px] lg:pt-[180px]
                    pb-[40px] sm:pb-[80px] lg:pb-[182px]">
                    <div
                        className="grid gap-[16px] lg:gap-[28px]
                        w-[312px] mx-auto 
                        sm:w-full sm:px-[8%]
                        lg:max-w-[522px] lg:pl-[8%] lg:pr-0 lg:mr-[70px] xl:pl-0">
                        <div className="flex sm:justify-center lg:justify-start">
                            <HeroTitle />
                        </div>
                        <p className="font-[Outfit] font-medium text-white text-[18px] sm:text-[20px] lg:mr-[10px]">
                            Si estás buscando nuevas formas de diseñar, innovar y crear. Prueba y aprende con nosotros y cumple esos objetivos que viene postergando.
                        </p>
                        {isLg &&
                            <Link to="nuestrosCursos"
                                smooth={true}
                                duration={500}> <BtnHero />
                            </Link>}
                    </div>
                </div>
                <div
                    className="w-full bg-gradient-to-tr from-[#72FFE6] to-[#27004F] relative
                    pt-[40px] lg:pt-[180px]">
                    <div className="w-[312px] mx-auto sm:w-full sm:px-[16%] lg:px-[8%]">
                        {!isLg &&
                            <Link
                                to="nuestrosCursos"
                                smooth={true}
                                duration={500} className="mb-[-40px] md:mb-[480px]">
                                <BtnHero />
                            </Link>}
                        <img
                            className="lg:absolute bottom-0 right-[4%] lg:left-[-47px]
                            w-[254px] ml-[80px] 
                            sm:w-auto sm:ml-[100px] 
                            md:ml-0 xl:max-w-[602px]"
                            src="/png/img-hero.png" alt="">
                        </img>
                    </div>

                </div>
            </section>

            <section id="Section 1"
                className="py-[40px] sm:py-[80px]">
                <div className="container">
                    <div className="flex justify-center">
                        <div>
                            <h2 className="relative text-[#12113F] z-10"> ¿Por qué elegirnos? </h2>
                            <div className="mx-auto relative z-0 bg-[#72FFE6]
                                w-[282px] h-[12px] top-[-16px]
                                sm:w-[552px] sm:h-[25px] sm:top-[-36px]">
                            </div>
                        </div>
                    </div>
                    <p className="pH2"> Forma parte de nuestra comunidad de aprendizaje contínuo y disfruta nuevos beneficios </p>
                    <div
                        className="grid gap-[32px] mt-[32px] justify-center
                        md:grid-cols-2 md:gap-[80px] md:mt-[64px]
                        lg:grid-cols-3 xl:gap-x-[132px] lg:mt-[80px] xl:px-[45px]">
                        <CardElegirnos numero="01" razon="Seguimiento" razon2="continuo" color="#12113F" />
                        <CardElegirnos numero="02" razon="Clases" razon2="dinámicas" color="#FFF" fondo="#12113F" />
                        <CardElegirnos numero="03" razon="Cursos" razon2="cortos" color="#12113F" />
                    </div>
                </div>
            </section>

            <div id="nuestrosCursos">
                <section id="Section 2"
                    className="py-[40px] sm:py-[80px] bg-azulP">
                    <div className="container">
                        <div className="flex justify-center">
                            <div>
                                <h2 className="relative text-white z-10"> Nuestros Cursos </h2>
                                <div className="mx-auto relative z-0 bg-[#54328C]
                                w-[242px] h-[12px] top-[-16px]
                                sm:w-[455px] sm:h-[25px] sm:top-[-36px]">
                                </div>
                            </div>
                        </div>
                        <p className="pH2 text-white"> Es el momento de desarrollar nuevas habilidades, revisa la información de nuestros cursos </p>
                        <div
                            className="grid gap-x-[80px] gap-y-[32px] mt-[24px] 
                        lg:grid-cols-2 lg:gap-y-[120px] xl:gap-x-[140px] sm:gap-y-[80px] sm:mt-[40px]">
                            <CardCurso
                                img="/png/curso1.png"
                                cursoname="Principios de UX"
                                inicio="INICIO: Martes 22 de Febrero"
                                informacion="Aprende de la mano de expertos del mundo UX y el Diseño de
                                Productos Digitales los primeros pasos y fundamentos de esta disciplina
                                centrada en los usuarios."
                                active={true}
                                category="Diseño UX" color="#12113F"
                                sesiones="8"
                                state={false}
                                pdf="https://prototips.org/pdf/Disen%CC%83o-ux-101-PROTOTIPS.pdf"
                                href="https://api.whatsapp.com/send?phone=+51933509472&text=Me%20gustar%C3%ADa%20conocer%20m%C3%A1s%20informaci%C3%B3n%20sobre%20este%20curso%20de%20Dise%C3%B1o%20UX%20101"
                                horario="Martes y Jueves -  7pm a 8:30pm">
                            </CardCurso>
                            <CardCurso
                                img="/png/imgDesarrolloweb.png"
                                cursoname="Aprende HTML5"
                                inicio="INICIO: Martes 22 de Febrero"
                                active={true}
                                state={true}
                                pdf="https://prototips.org/pdf/Desarrollo-web-basico-PROTOTIPS.pdf"
                                href="https://api.whatsapp.com/send?phone=+51933509472&text=Me%20gustar%C3%ADa%20conocer%20m%C3%A1s%20informaci%C3%B3n%20sobre%20este%20curso%20de%20Desarrollo%20Web:%20HTML5%20b%C3%A1sico"
                                informacion="Adquiere las habilidades para crear páginas web atractivas y funcionales mediante el aprendizaje de HTML y CSS."
                                category="Desarrollo web" color="#54328C"
                                sesiones="8"
                                horario="Martes y Jueves - 7pm a 8:30pm">
                            </CardCurso>

                        </div>
                    </div>
                </section>
            </div>

            <div id="proximosCursos">
                <section
                    className="py-[40px] sm:py-[80px]">
                    <div className="container">
                        <div className="flex justify-center">
                            <div>
                                <h2 className="relative text-[#12113F] z-10"> Próximos Cursos </h2>
                                <div className="mx-auto relative z-0 bg-[#72FFE6]
                                w-[282px] h-[12px] top-[-16px]
                                sm:w-[552px] sm:h-[25px] sm:top-[-36px]">
                                </div>
                            </div>
                        </div>
                        <p className="pH2"> Encuentra más cursos para poder complementar tus habilidades  </p>
                        <div
                            className="grid gap-y-[24px] mt-[24px] sm:mt-[40px]
                        lg:grid-cols-2 lg:gap-x-[30px] lg:gap-y-[56px] lg:mt-[56px]">
                            <CardProximosCursos
                                img="/png/proximosDiseno.png"
                                category={"Diseño"}
                                titulo={"Diseño visual"}
                                resumen={"Con este curso aprenderás a diseñar visualmente prototipos, presentaciones y sitios webs impactantes."}
                            />
                            <CardProximosCursos
                                img="/png/cursoFrontend.png"
                                category={"Tecnología"}
                                titulo={"Desarrollo Web Intermedio"}
                                resumen={"Con este curso, adquirirás habilidades en los lenguajes de programación JavaScript, HTML y CSS."}
                            />
                        </div>
                    </div>
                </section>
            </div>

            <section id="Sect-ion 4"
                className="py-[32px] sm:py-[24px] bg-azulP">
                <div className="container">
                    <div
                        className="">
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={"https://www.ciclos.studio/"}
                            className="flex justify-center">
                            <img src="/svg/logo-ciclos.svg" alt='Ciclos Studio' className="w-[80px] sm:w-auto" />
                        </a>
                    </div>
                </div>
            </section>

            <section id="Section 5">

            </section>

        </motion.div>
    )
}
export default Home