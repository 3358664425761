import React from 'react'
import { useForm } from 'react-hook-form'
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'
const FormCurso = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm(
        { defaultValues: { yes_i_understand: false } }
    )

    const onSubmit = (data, e) => {
        const id = toast.loading("Please wait...")
        emailjs.sendForm('service_d48hbft', 'template_dc0km7k', e.target, 'HzS7Vxcwah1NMMLRU')
            .then(res => {
                toast.update(id,
                    {
                        render: "Información enviada",
                        type: "success",
                        autoClose: 4000,
                        isLoading: false,
                        draggable: true
                    })
                reset()
            })
            .catch (error => toast.update(id, {
                render: "Ocurrio un error",
                type: "Error",
                autoClose: 4000,
                isLoading: false,
                draggable: true
            }))
    }

const errorStyle = {
    color: "rgba(214, 10, 17, 0.64)",
    fontSize: "16px",
    marginLeft: "18px"
}

const inputErrorStyle = {
    boxShadow: "0px 2px 8px rgba(214, 17, 17, 0.64)",
    borderStyle: "none"
}

return (
    <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid gap-[24px] sm:gap-[40px] xl:justify-center">
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </div>
        <div className="grid">
            <label> Nombres : </label>
            <input
                id='nombre'
                type="text"
                style={errors.nombre && inputErrorStyle}
                placeholder='Nombres'
                {...register("nombre",
                    { required: true })}>
            </input>
            {
                errors.nombre && <span style={errorStyle}>Este campo es requerido</span>
            }
        </div>

        <div className="grid">
            <label> Apellidos : </label>
            <input
                id='apellido'
                type="text"
                style={errors.apellido && inputErrorStyle}
                placeholder='Apellidos'
                {...register("apellido",
                    { required: true })}>
            </input>
            {
                errors.apellido && <span style={errorStyle}>Este campo es requerido</span>
            }
        </div>

        <div className="grid">
            <label> Teléfono : </label>
            <input
                id='telefono'
                type="text"
                style={errors.telefono && inputErrorStyle}
                placeholder='Teléfono'
                {...register("telefono",
                    { required: true })}>
            </input>
            {
                errors.telefono && <span style={errorStyle}>Este campo es requerido</span>
            }
        </div>
        <div className="grid">
            <label> Correo : </label>
            <input
                id='correo'
                type="text"
                style={errors.correo && inputErrorStyle}
                placeholder='Correo'
                {...register("correo",
                    { required: true })}>
            </input>
            {
                errors.correo && <span style={errorStyle}>Este campo es requerido</span>
            }
        </div>
        <div className="flex justify-center">
            <input
                type="submit"
                className="bg-lilaP font-[Outfit] text-center text-white border-0
                     w-[128px] h-auto py-[8px] font-bold text-[16px] cursor-pointer
                     sm:w-[184px] sm:py-[6px] sm:font-semibold sm:text-[24px]">
            </input>
        </div>
    </form>
)
}

export default FormCurso