import React from "react";

const CardElegirnos = (props) => {
    
    return (
        <div
            style={{background: props.fondo}}
            className=" grid border-[2px] border-azulP items-center justify-between
            gap-[32px] w-[282px] px-[16px] pt-[24px] pb-[98px] 
            md:gap-[16px] md:w-auto md:px-[24px] md:pt-[32px] md:pb-[64px]">

            <div>
                <p className="text-lilaP font-[Outfit] font-bold text-[32px] sm:text-[56px]">
                    {props.numero}
                </p>
                <div className="bg-verdeAgua h-[6px] w-[36px] sm:w-[32px] sm:mt-[8px]"/>
            </div>

            <p 
                style={{color: props.color}}
                className="font-[Playfair] font-black  
                text-[28px] mt-[16px] sm:text-[32px] sm:mt-0">
                {props.razon}
                <br/>
                {props.razon2}
            </p>
                
        </div>
    )
}
export default CardElegirnos