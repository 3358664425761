import React from "react";

const BtnHero = () => {
    
    return (
        <button
            className="bg-[#986DFA] font-[Outfit] font-bold text-white text-center
            w-[116px] py-[8px] text-[16px] hover:bg-[#986DFA]/80 transition-all ease-out
            sm:w-[237px] sm:py-[16px] sm:text-[20px]">
            VER CURSOS
        </button>
    )
}
export default BtnHero