import React from "react";
import { Link } from 'react-router-dom'
import BtnComprarAhora from "../buttons/BtnComprarAhora";
import BtnVerCurso from "../buttons/BtnVerCurso";
import CardCategories from "./CardCategories";

const CardCurso = (props) => {

    return (
        <div
            className="bg-white w-full xl:w-[520px] transition-all
            ease-out">
            <img src={props.img} alt="Nuestro Curso" className="object-cover w-full h-[254px] sm:h-[290px]" />

            <div className="p-[24px] sm:py-[40px] sm:px-[36px]">
                <div className="grid gap-[30px] xl:gap-0 grid-cols-[1.5fr_1.5fr] xl:flex xl:justify-between items-center">
                    <CardCategories
                        category={props.category}
                        color={props.color}
                    />
                    <p className="font-[Outfit] font-semibold text-[14px] sm:text-[18px]">
                        {props.inicio}
                    </p>
                </div>

                <h3
                    className="font-[Playfair] font-black text-[24px] py-[16px] 
                    sm:text-[32px] sm:pt-[10px] sm:pb-[12px]">
                    {props.cursoname}
                </h3>

                <p className="pCard text-[#12113F]"> {props.informacion} </p>

                <div
                    className="xl:flex justify-between items-center
                    pt-[16px] pb-[16px] sm:pb-[20px]">
                    <p className="font-[Outfit] font-bold text-[16px] sm:text-[18px]">
                        <span>{props.sesiones}</span> sesiones
                    </p>
                    <p className="font-[Outfit] font-normal text-[14px] sm:text-[18px]">
                        {props.horario}
                    </p>
                </div>
                {
                    <div className={props.state ? "mt-[24px]":""}>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={props.pdf}
                            className="flex justify-center items-center">
                            <BtnVerCurso />
                        </a>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={props.href}
                            className="flex justify-center items-center mt-[16px] sm:mt-[24px]">
                            <BtnComprarAhora />
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}
export default CardCurso
