import React from "react";

const BtnComprarAhora = () => {
    return (
        <button
            className="bg-[#12113F] text-[#72FFE6] font-[Outfit] font-bold text-center
            w-full py-[10px] text-[16px] hover:bg-[#12113F]/90
            sm:w-[322px] sm:py-[16px] sm:text-[24px]">
            COMPRAR AHORA
        </button>
    )
}
export default BtnComprarAhora