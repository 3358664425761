import React from "react";

const CardSesiones = (props) => {
    
    return (
        <div
            className="w-full h-[62px] sm:h-[80px] border-b-[1px] border-white">
                <p className="font-[Outfit] font-semibold text-verdeAgua text-[14px] pl-[17px] sm:text-[16px] sm:pl-[33px]"> 
                    Sesión {" "} <span> {props.sesion}</span> 
                </p>
                <div className="flex items-center gap-[8px] sm:gap-[17px]">
                    <div className="bg-lilaP rounded-full w-[8px] h-[8px] sm:w-[16px] sm:h-[16px]"/>
                    <p className="font-[Playfair] text-[16px] font-semibold sm:text-[32px] sm:font-medium text-white">
                        {props.text}
                    </p>
                </div>
        </div>
    )
}
export default CardSesiones