import React from "react";

const HeroTitle = (props) => {

    return (
        <div
            className=" w-full  relative z-0">
            <div
                className="font-[Outfit] font-bold text-verdeAgua
                text-[36px] sm:text-[56px] text-center   
                lg:text-start xl:text-[64px]">
                <span 
                    className="font-[Playfair] font-black text-white
                    after:left-0 after:w-[144px] sm:after:w-[220px]
                     bgLine ">
                    Adquiere{" "}
                </span>
                nuevas 
                <br />
                habilidades {" "}
                <span
                    className="font-[Playfair] font-black text-white
                    after:right-0 after:w-[54px] sm:after:w-[140px] 
                    bgLine">
                    hoy
                </span>
            </div>
        </div>
    )
}

export default HeroTitle